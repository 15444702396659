import { Injectable } from '@angular/core';
import { AuthHttpService } from "@appcore/services/index";
import { map, Observable, ReplaySubject, take, tap } from "rxjs";
import { SelectedCompany } from "@appcore/services/selected-company";
import { IRole, IRoleLocalized, IStyrkRole } from "@appcore/models/role";

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    private _roles$ = new ReplaySubject<IRole[] | null>(1);
    private _styrkRoles$ = new ReplaySubject<IStyrkRole [] | null>(1);
    private rolesExpire = 0;

    private styrkRolesExpire = 0;

    constructor(private authHttp: AuthHttpService, private company: SelectedCompany) {

    }

    get roles$(): Observable<IRole[] | null> {
        this.fetch();
        return this._roles$.asObservable();
    }

    get styrkRoles$(): Observable<IStyrkRole[] | null> {
        this.fetchstyrkRoles();
        return this._styrkRoles$.asObservable();
    }
    private fetchstyrkRoles() {
        if (this.styrkRolesExpire === 0 || Math.floor((Date.now() - this.styrkRolesExpire) / 1000) > 60) {
            this.styrkRolesExpire = Date.now();
            this.authHttp.get<IStyrkRole[] | null>(`${this.baseUrl}/styrk`)
                .pipe(take(1), map(c => c.body))
                .subscribe({
                    next: styrkRoles => {
                        this._styrkRoles$.next(styrkRoles);

                    }, error: err => {
                        this._styrkRoles$.error(err);
                        this.styrkRolesExpire = 0;
                    }
                });
        }
        return;
    }

    create(role: { name: string }): Observable<IRole | null> {
        return this.authHttp.post<IRole>(this.baseUrl, {...role})
            .pipe(
                map(c => c.body),
                tap(() => {
                    this.fetch(true);
                }));
    }

    update(role: IRole): Observable<IRole | null> {
        return this.authHttp.put<IRole>(this.baseUrl, {...role})
            .pipe(
                map(c => c.body),
                tap(() => {
                    this.fetch(true);
                }));
    }

    delete(roleId: number): Observable<any> {
        return this.authHttp.delete<IRole>(`${this.baseUrl}/${roleId}`)
            .pipe(
                map(c => c.body),
                tap(() => {
                    this.fetch(true);
                }));
    }


    private get baseUrl() {
        const companyId = this.company.lastShippingCompany;
        return `/api/shipping-company/${companyId}/roles`
    }

    private fetch(force: boolean = false) {
        if (!force && this.rolesExpire > Date.now()) {
            return;
        }

        // Cache for 60 sec
        this.rolesExpire = Date.now() + (60 * 1000);
        this.authHttp.get<IRole[]>(this.baseUrl)
            .pipe(take(1), map(c => c.body))
            .subscribe({
                next: roles => {
                    this._roles$.next(roles);

                }, error: err => {
                    this._roles$.error(err);
                    this.rolesExpire = 0;
                }
            });
    }

    getLocalizedRoles() {
        return this.authHttp.get<IRoleLocalized[]>(`${this.baseUrl}/localized`)
            .pipe(map(c => c.body));
    }

    setLocalizedRoles(roleId: number, localizedRoles: IRoleLocalized[]) {
        return this.authHttp.post(`${this.baseUrl}/localized/${roleId}`, localizedRoles)
            .pipe(map(c => c.body));
    }
}
