const apiUrl = 'https://beta.api.havtech.no';
const scope = 'user org admin';

const tokenOptions = {
    audience: 'https://havtech-api-test.azurewebsites.net',
    scope: scope
};

export const environment = {
    production: true,
    apiUrl: apiUrl,
    tokenOptions: tokenOptions,
    includeDarkTheme: false,
    auth: {
        domain: 'havtech-dev.eu.auth0.com',
        clientId: 'i4O7CB56rws9JTYsaaBm73ixUL7PKpAm',
        redirectUri: window.location.origin,
        audience: tokenOptions.audience,
        scope: tokenOptions.scope,
        httpInterceptor: {
            allowedList: [
                {
                    uri: `${apiUrl}/*`,
                    tokenOptions: tokenOptions,
                    allowAnonymous: true
                }
            ]
        }
    }
};
