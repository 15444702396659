import { IShippingCompanySettings } from "@appcore/models/shipping-company";
import { FileType } from "@appcore/models/document";

export class User {
    id?: string;
    email?: string;
    name?: string;
    [key: string]: any;

    constructor(user: any) {
        Object.assign(this, user);
        this.id = user?.sub;
    }
}

export interface IUserProfile {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    defaultRole: string;
    profilePicture: string;
    access: string[];
    companies: ICompanyUserDetails[];
    /**
     * Id of the company the current user is connected to
     */
    currentCompany: number;
    language: string;
    nationality: string;
    dateOfBirth: string;
    cityOfBirth: string;
    localName: string;
    patronymic: string;
    permissions: IPermission[];
}

export interface IPermission {
    shippingCompanyId: number;
    access: string;
}

export interface ICompanyUser {
    userId: string;
    shippingCompanyId: number;
}

export interface ICompanyUserDetails extends ICompanyUser {
    userEmail: string;
    userName: number;
    shippingCompanyName: string;
    isOfficer: boolean;
    settings: IShippingCompanySettings;
    isChosenCompany: boolean;
}


export interface IUserSeaServiceYearly {
    year: number;
    days: number;
}

export interface IUserDepartment extends IUserProfile {
    departmentId: number,
    departmentName: string,
    permissionGroupId: number
}

export interface IShippingCompanyDepartmentUser {
    id: number;
    name: string;
    shippingCompanyDepartmentId: number;
    userId: string;
}

export interface IUserRelation {
    id: number;
    userId: string;
    name: string;
    dateOfBirth: Date;
    phone: string;
    address: string;
    relationsType: Relationtype;
    nextOfKin: boolean;
    expanded: boolean;
}

export interface IUserDocumentation {
    id: number;
    userId: string;
    documentId: string;
    issuedBy: string;
    countryOfOriginId?: number;
    validInCountryId?: number;
    validFrom?: Date;
    validTo?: Date | null;
}

export interface IUserDocumentationDetailed extends IUserDocumentation {
   description: string;
   fileName: string;
   fileType: FileType;
   contentType: string;
   fileUrl: string;
   extractedProfilePictureUrl?: string;
}

export interface IUserAdditionalInformation {
    id: number;
    userId: string;
    additionalInformationType?: AdditionalInformationType;
    value: string;
}

export interface IAirport {
    id: number;
    name: string;
    iata: string;
}

export interface IFrontPageSettings {
    // as long as all settings are boolean, we can use this, use more multiple types if needed
    [key: string]: boolean;
}

export interface IExternalUserInfo {
    lastLogin?: Date
}

export enum frontPageSettingsStatus {
    CLEAR,
    GET_ERROR,
    POST_ERROR,
    POST_SUCCESS,
    GET_SUCCESS,
}

/**
 * This is the enum for the front page settings.
 * Add new settings here by adding it to enum.
 * Key can be anything.
 * Value needs to be the same as shipping_company_admin_settings if there is an equivalent one in company setting,
 * else this can be anything. Then add lang with admin.front.page.setting.[value] in translation files.
 */
export enum FrontPageSettingNames {
    LOT = 'lot',
    GANTT = 'gantt',
    TASKS = 'tasks',
    SEARCH = 'search',
    QUICK_ACTIONS = 'quickActions',
}

export enum Relationtype {
    Spouse = "Spouse",
    Partner = "Partner",
    Child = "Child",
    Parent = "Parent",
    Grandparent = "Grandparent",
    Sibling = "Sibling",
    Friend = "Friend",
    Other = "Other"
}

export enum AdditionalInformationType {
    SecondaryEmail = "SecondaryEmail",
    SecondaryPhone = "SecondaryPhone",
    LandlinePhone = "LandlinePhone"
}

export interface ICrewMissingDocumentSummaryItem {
    name: string,
    date: string,
    shouldTranslate: boolean,
    externalSystem: string,
}

export interface ICrewMissingDocumentSummary {
    missingDocuments: ICrewMissingDocumentSummaryItem[],
    soonExpiredDocuments: ICrewMissingDocumentSummaryItem[],
    toBeApprovedDocuments: ICrewMissingDocumentSummaryItem[],
    thirdPartyDocuments: ICrewMissingDocumentSummaryItem[],
}
