import { IconSet, regular, solid } from "@appcore/models/icon";

export const icons = {
    login: new IconSet('fa-sign-in-alt', solid),
    logout: new IconSet('fa-sign-out-alt', solid),
    menu: new IconSet('fa-bars', solid),
    sliders: new IconSet('fa-sliders-h', solid),
    list: new IconSet('fa-list', solid),
    language: new IconSet('fa-language', solid),

    admin: new IconSet('fa-user-shield', solid),

    messages: new IconSet('fa-comments', regular),
    messagesSolid: new IconSet('fa-comments', solid),
    messageNew: new IconSet('fa-comment-medical', solid),

    company: new IconSet('fa-briefcase', solid),

    ship: new IconSet('fa-ship', solid),
    waves: new IconSet('fa-water', solid),
    plane: new IconSet('fa-plane', solid),
    fish: new IconSet('fa-fish', solid),
    anchor: new IconSet('fa-anchor', solid),
    clipboard: new IconSet('fa-clipboard-list', solid),

    user: new IconSet('fa-user', solid),
    userCircle: new IconSet('fa-user-circle', solid),
    users: new IconSet('fa-users'),
    userAdd: new IconSet('fa-user-plus', solid),
    userCheck: new IconSet('fa-user-check', solid),
    userCog: new IconSet('fa-user-cog', solid),

    circle: new IconSet('fa-circle', regular),
    arrowStart: new IconSet('fa-step-backward', solid),
    arrowEnd: new IconSet('fa-step-forward', solid),

    gear: new IconSet('fa-cog', solid),
    gears: new IconSet('fa-cogs', solid),
    delete: new IconSet('fa-trash-alt', solid),
    save: new IconSet('fa-save', solid),
    minus: new IconSet('fa-minus-square', regular),
    plus: new IconSet('fa-plus-square', regular),
    add: new IconSet('fa-plus', solid),
    remove: new IconSet('fa-minus', solid),
    send: new IconSet('fa-paper-plane', regular),
    clear: new IconSet('fa-times', solid),
    close: new IconSet('fa-times', solid),
    closeSquare: new IconSet('fa-window-close', regular),
    edit: new IconSet('fa-edit', regular),
    lock: new IconSet('fa-lock', solid),
    unlock: new IconSet('fa-unlock-alt', solid),
    warning: new IconSet('fa-exclamation-triangle', solid),
    info: new IconSet('fa-info-circle', solid),
    linkOpen: new IconSet('fa-external-link-alt', solid),
    download: new IconSet('fa-download', solid),
    search: new IconSet('fa-search', solid),

    caretLeft: new IconSet('fa-angle-left', solid),
    caretRight: new IconSet('fa-angle-right', solid),
    caretUp: new IconSet('fa-angle-up', solid),
    caretDown: new IconSet('fa-angle-down', solid),

    coins: new IconSet('fa-coins', solid),
    money: new IconSet('fa-money-bill-alt', regular),
    calendar: new IconSet('fa-calendar-day', solid),
    calendarAlt: new IconSet('fa-calendar-alt', solid),
    navBack: new IconSet('fa-reply', solid),

    theme: new IconSet('fa-fill-drip', solid),
    debug: new IconSet('fa-bug', solid),

    file: new IconSet("fa-file", solid),
    bed: new IconSet("fa-bed", solid),
    arrowDown: new IconSet("fa-arrow-down", solid),
    arrowUp: new IconSet("fa-arrow-up", solid),
    thumbsUp: new IconSet("fa-thumbs-up", solid),
    thumbsDown: new IconSet("fa-thumbs-down", solid),
    check: new IconSet("fa-check", solid),
    book: new IconSet("fa-book", solid),
    copy: new IconSet("fa-copy", solid),
    backspace: new IconSet("fa-backspace", solid),
    map: new IconSet("fa-map", solid),
    camera: new IconSet("fa-camera", solid),
    clock: new IconSet("fa-clock", solid),
    chemical: new IconSet("fa-flask", solid),
    passport: new IconSet("fa-passport", solid),
    fillDrip: new IconSet("fa-fill-drip", solid),
    department: new IconSet("fa-building", solid),
    envelope: new IconSet("fa-envelope", solid),
    crewChange: new IconSet("fa-people-arrows", solid)
};
