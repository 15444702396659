import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { SelectedCompany } from "@appcore/services/selected-company";

@Component({
    selector: 'app-logo',
    template: `
        <img [fxHide]="isDark" [class]="classList"
             routerLink="/dashboard/organization/view/{{this.company.lastShippingCompany}}"
             src="../../../../assets/logo.png"
             alt="Ombord">
        <!-- TODO RICHARD isDark is currently only used in crew app. The routerlink above does not work for crew app.-->
        <img *ngIf="isDark" [class]="classList"
             routerLink="/"
             src="../../../../assets/logo-dark.png" alt="Ombord">
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit, OnDestroy {
    @Input() classList = '';
    isDark = false;

    private obs: MutationObserver | undefined;

	constructor(@Inject(DOCUMENT) private document: Document, private changeDetector: ChangeDetectorRef, public company: SelectedCompany) {

    }

    ngOnInit() {
        this.checkTheme();
        this.obs = new MutationObserver(e => {
            this.checkTheme();
        });
        this.obs.observe(this.document.documentElement, {attributes: true, attributeFilter: ['class'], childList: false, characterData: false});
    }

    ngOnDestroy() {
        this.obs?.disconnect();

    }

    private checkTheme(){
        this.isDark = this.document.documentElement.classList.contains('theme-dark');
        this.changeDetector.markForCheck();
    }
}
